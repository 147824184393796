var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-view',[_c('v-row',{staticClass:"fill-height mx-n3",attrs:{"align":"start","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"crudDialog",attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n12 mb-n12 ml-6",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" AJOUTER UNE DEMANDE ")])]}}]),model:{value:(_vm.addEventDialog),callback:function ($$v) {_vm.addEventDialog=$$v},expression:"addEventDialog"}},[_c('add-support-form',{attrs:{"overlay":_vm.overlay},on:{"canceled":_vm.closeEventDialog,"addSupport":_vm.addSupport}})],1),_c('toggle-button',{staticClass:"ml-3",attrs:{"font-size":16,"value":_vm.sliderInit,"width":100,"height":35,"color":{
          checked: 'var(--v-primary-base)',
          unchecked: 'var(--v-primary-base)'
        },"labels":{
          checked: 'complet',
          unchecked: 'rapide'
        }},on:{"change":function($event){return _vm.dataRange()}}}),_c('onglet-technique')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }