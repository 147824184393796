<template>
  <v-card>
    <v-card-title>
      <span :class="{ headline: $vuetify.breakpoint.mdAndUp }">Demande</span>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn
          color="blue lighten-2"
          class="actionBtn"
          :disabled="!canAddEvent"
          @click="submitForm"
          >Ajouter</v-btn
        >
        <v-btn color="error" @click="cancel">Annuler</v-btn>
      </v-card-actions>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-col
          v-if="!isKitUser"
          cols="12"
          class="d-flex flex-column ml-n5 mb-n4 mt-n6"
        >
          <v-file-input
            accept="image/*"
            label="Ajouter une photo"
            v-model="photo"
          ></v-file-input>
          <div class="d-flex justify-center" v-if="finalImg">
            <v-card class="mx-auto" width="150">
              <v-img :src="finalImg.src" height="150px"></v-img>
            </v-card>
            <div class="zoneInfo">
              <div class="titre">{{ photo.name }}</div>
              <div class="detailInfo">
                Type : {{ photo.type }} <br />Taille originale :<br />
                {{ W_org }} x {{ H_org }} px <br />Taille finale :<br />
                {{ W_fin }} x {{ H_fin }} px
              </div>
            </div>
          </div>
        </v-col>
        <v-row class="d-flex flex-wrap">
          <!--<v-col class="d-flex mb-n6" cols="12">
            <v-select
              label="Type de demande"
              item-text="name"
              item-value="id"
              v-model="category"
              return-object
              :items="demandeItems"
            ></v-select>
          </v-col>-->
          <v-col v-if="!isKitUser" class="d-flex mb-n6" cols="12">
            <v-select
              label="Lieux"
              item-text="lieux"
              item-value="id"
              v-model="lieux"
              return-object
              :items="lgtItems"
            ></v-select>
          </v-col>
          <v-col class="d-flex mb-n6" cols="12">
            <v-select
              label="Objet de la demande *"
              item-text="title"
              item-value="id"
              v-model="title"
              return-object
              :items="titleItems"
            ></v-select>
          </v-col>
          <v-col cols="12 mb-n6">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              value="example"
              label="E-mail *"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12 mb-n6">
            <v-text-field
              type="number"
              v-model="tel"
              label="Telephone"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12 mb-n6" class="pa-0">
            <v-textarea
              solo
              rows="3"
              dense
              name="input-7-4"
              label="Description"
              :loading="eventLoading"
              clearable
              v-model="content"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      * obligatoire
    </v-card-text>

    <v-overlay :value="overlay">
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          indeterminate
          size="64"
          v-if="progress == 0"
        ></v-progress-circular>
        <v-progress-circular
          v-else
          :rotate="360"
          :size="100"
          :width="15"
          indeterminate
          color="primary"
        >
          <span class="white--text">
            <strong>{{ progress }} %</strong>
          </span>
        </v-progress-circular>
        <span class="mt-5">
          Creation en cours...
        </span>
      </div>
    </v-overlay>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { blobToImage } from "../../utils/upload";
import loadImage from "blueimp-load-image/js/load-image";
require("blueimp-load-image/js/load-image.all.min.js");

const Res_target = 4000000;

export default {
  props: {
    eventLoading: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    event: {
      type: Object,
      default() {
        return {
          start: undefined,
          end: undefined
        };
      }
    }
  },
  data() {
    return {
      event_type: 0,
      content: undefined,
      tel: undefined,
      email: undefined,
      title: undefined,
      lieux: undefined,
      //category: undefined,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      photo: undefined,
      finalImg: undefined,
      exifImg: undefined,
      W_org: undefined,
      H_org: undefined,
      Res_target,
      W_fin: undefined,
      H_fin: undefined,
      Ratio_comp: undefined,
      isKitAdmin: false,
      isKitUser: false
    };
  },

  methods: {
    cancel() {
      this.$emit("canceled");
      this.email = undefined;
      this.tel = undefined;
      //this.category = undefined;
      this.content = undefined;
      this.title = undefined;
      this.lieux = undefined;
      this.photo = undefined;
      this.exifImg = undefined;
    },
    addSupport() {
      const payload = {
        content: this.content,
        title: this.title,
        tel: this.tel,
        email: this.email,
        //category: this.category,
        lieux: this.lieux,
        photo: this.finalImg,
        exifimg: this.exifImg
      };

      this.$emit("addSupport", {
        input: payload
      });
    },
    submitForm() {
      this.addSupport();
    },
    resetForm() {
      this.content = undefined;
      this.guest = undefined;
      this.tel = undefined;
      this.email = undefined;
      //this.category = undefined;
      this.title = undefined;
      this.lieux = undefined;
      this.photo = undefined;
      this.exifImg = undefined;
    },
    loadEquiment() {
      let list = [];
      let tickets = this.getAllTickets.filter(
        ticket =>
          [1, 9, 10].includes(ticket.itilcategories_id) && ticket.status < 5
      );
      tickets.forEach(ticket => {
        const equipment = ticket.equipment;
        if (equipment && equipment.length > 0) {
          list = list.concat({
            eq: equipment,
            ent: parseInt(ticket.entities_id),
            id: parseInt(JSON.parse(ticket.name).itemtypeID)
          });
        }
      });
      let list2 = this.multiSort(list, {
        eq: "asc"
      });
      let unique = this.multiDimensionalUnique(list2);
      return unique[0];
    },
    multiDimensionalUnique(arr) {
      var uniques = [];
      var itemsFound = {};
      for (var i = 0, l = arr.length; i < l; i++) {
        var stringified = JSON.stringify(arr[i]);
        if (itemsFound[stringified]) {
          continue;
        }
        uniques.push(arr[i]);
        itemsFound[stringified] = true;
      }
      return uniques;
    },
    multiSort(array, sortObject = {}) {
      const sortKeys = Object.keys(sortObject);

      // Return array if no sort object is supplied.
      if (!sortKeys.length) {
        return array;
      }

      // Change the values of the sortObject keys to -1, 0, or 1.
      for (let key in sortObject) {
        sortObject[key] =
          sortObject[key] === "desc" || sortObject[key] === -1
            ? -1
            : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
      }

      const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;

        if (a === b) {
          // If the values are the same, do not switch positions.
          return 0;
        }

        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
      };

      return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;

        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
            const direction = sortObject[key];

            sorted = keySort(a[key], b[key], direction);
            index++;
          }
        }
        return sorted;
      });
    },
    async uploadImage() {
      const canvas = document.createElement("canvas"),
        //ctx = canvas.getContext("2d"),
        img = await blobToImage(this.photo);

      this.W_org = img.width;
      this.H_org = img.height;

      this.Res_org = this.W_org * this.H_org;
      this.Ratio_comp = Math.sqrt(Math.min(Res_target / this.Res_org, 1));
      this.W_fin = Math.round(this.W_org * this.Ratio_comp);
      this.H_fin = Math.round(this.H_org * this.Ratio_comp);
      canvas.width = this.W_fin;
      canvas.height = this.H_fin;
      if (this.photo) {
        const data = await loadImage(
          this.photo,
          {
            maxWidth: this.W_fin,
            maxHeight: this.H_fin,
            meta: true,
            orientation: true,
            canvas: true
          } // Options
        );
        /*.then(function(data) {
            if (!data.imageHead)
              throw new Error("Could not parse image metadata");
            self.finalImg = data.image;
            loadImage.writeExifData(data.imageHead, data, "Orientation", 1);

            return new Promise(function(resolve) {
              data.image.toBlob(function(blob) {
                data.blob = blob;
                resolve(data);
              }, "image/jpeg");
            });
          })
          .then(function(data) {
            return loadImage.replaceHead(data.blob, data.imageHead);
          })
          .then(function(blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", self.photo.name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(function(err) {
            console.error(err);
          });*/

        data.exif &&
          loadImage.writeExifData(data.imageHead, data, "Orientation", 1);
        //this.finalImg = data.image;
        this.photo.src = data.image.src;

        let newBlob = await new Promise(function(resolve) {
          data.image.toBlob(function(blob) {
            data.blob = blob;

            resolve(data);
          }, "image/jpeg");
        });

        data.blob = data.exif
          ? await loadImage.replaceHead(data.blob, data.imageHead)
          : data.blob;

        newBlob = data.blob;
        this.exifImg = new File([newBlob], this.photo.name, {
          type: this.photo.type
        });
        this.finalImg = await blobToImage(newBlob);

        /*ctx.drawImage(data.image, 0, 0, this.W_fin, this.H_fin);
        canvas.toBlob(async blob => {
          this.exifImg = new File([blob], this.photo.name, {
            type: "image/jpeg"
          });
        }, this.photo.type);*/
      }
    }
  },
  watch: {
    event: {
      handler: function() {
        if (!this.event.start) {
          this.resetForm();
        } else {
          this.initData();
        }
      },
      deep: true
    },
    photo() {
      if (this.photo) {
        this.uploadImage();
      }
    }
  },
  computed: {
    ...mapGetters(["getAllTickets", "getEquipments", "getUserId"]),
    getTickets() {
      return this.getAllTickets.filter(
        ticket =>
          [1, 9, 10].includes(ticket.itilcategories_id) && ticket.status < 5
      );
    },
    canAddEvent() {
      return !!this.title && !!this.email; // && !!this.category && !!this.tel
    },
    isDirectLink() {
      return this.$route.meta.directLink;
    },
    lgtItems() {
      let list = [];
      this.getEquipments.forEach(lgt => {
        list = list.concat({
          lgt: lgt.name,
          ent: parseInt(lgt.entities_id),
          id: lgt.id
        });
      });
      let list2 = this.multiSort(list, {
        lgt: "asc"
      });
      let unique = this.multiDimensionalUnique(list2);
      let len = unique.length;
      let lgtList = [];
      for (let i = 0; i < len; i++) {
        lgtList = lgtList.concat(
          unique[i].lgt + " (" + unique[i].ent + "@" + unique[i].id + ")"
        );
      }
      //lgtList = lieuxList.concat("INPUT (308@284)");
      return lgtList;
    },
    lieuxItems() {
      let list = [];
      let tickets = this.getAllTickets.filter(
        ticket =>
          [1, 9, 10].includes(ticket.itilcategories_id) && ticket.status < 5
      );
      tickets.forEach(ticket => {
        const equipment = ticket.equipment;
        if (equipment && equipment.length > 0) {
          list = list.concat({
            eq: equipment,
            ent: parseInt(ticket.entities_id),
            id: parseInt(JSON.parse(ticket.name).itemtypeID)
          });
        }
      });
      let list2 = this.multiSort(list, {
        eq: "asc"
      });
      let unique = this.multiDimensionalUnique(list2);
      let len = unique.length;
      let lieuxList = [];
      for (let i = 0; i < len; i++) {
        lieuxList = lieuxList.concat(
          unique[i].eq + " (" + unique[i].ent + "@" + unique[i].id + ")"
        );
      }
      lieuxList = lieuxList.concat("INPUT (308@284)");
      return lieuxList;
    },
    titleItems() {
      if (this.isKitUser || this.isKitAdmin) {
        return ["LOCATAIRES", "STAFF", "PLATEFORME", "EDL", "AUTRE"];
      } else return ["CVC", "ELEC", "PLOMBERIE", "MENAGE", "AUTRE"];
    },
    demandeItems() {
      if (this.isKitUser || this.isKitAdmin) {
        return ["BUG", "FEAT"];
      } else return ["Projet", "Commissionnement", "Exploitation", "Public"];
    }
  }
};
</script>
