<template>
  <default-view>
    <v-row align="start" no-gutters justify="center" class="fill-height mx-n3">
      <v-col cols="12">
        <v-dialog
          ref="crudDialog"
          v-model="addEventDialog"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-n12 mb-n12 ml-6"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              AJOUTER UNE DEMANDE
            </v-btn>

          </template>
          <add-support-form
            :overlay="overlay"
            @canceled="closeEventDialog"
            @addSupport="addSupport"
          ></add-support-form>
        </v-dialog>
        <toggle-button
          class="ml-3"
          :font-size="16"
          :value="sliderInit"
          :width="100"
          :height="35"
          :color="{
            checked: 'var(--v-primary-base)',
            unchecked: 'var(--v-primary-base)'
          }"
          :labels="{
            checked: 'complet',
            unchecked: 'rapide'
          }"
          @change="dataRange()"
        />
        <onglet-technique> </onglet-technique>
      </v-col>
    </v-row>
  </default-view>
</template>
<script>
import OngletTechnique from "../components/technique/Onglet";
import DefaultView from "../components/DefaultView";
import AddSupportForm from "../components/support/AddSupport.vue";
import TICKET_API from "../api/ticket";
import Swal from "sweetalert2";
import { mySwalClass } from "../utils/alert";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: { OngletTechnique, DefaultView, AddSupportForm, ToggleButton },
  data: () => ({
    overlay: false,
    addEventDialog: false,
    slider: ""
  }),
  methods: {
    addSupport({ input }) {
      //let urlcourante = document.location.href;
      //let ent = urlcourante.split("/")[5];
      //let it = urlcourante.split("/")[9];
      let it = "PluginGenericobjectLogement";
      //let itID = urlcourante.split("/")[11];
      let itID = input.lieux
        .split("")
        .reverse()
        .join("")
        .split("@")[0]
        .split("")
        .reverse()
        .join("")
        .split(")")[0];
      //let cat = input.category == "BUG" ? 9 : 10;
      let cat = 1;
      const payload = {
        name: JSON.stringify({
          itemtype: it,
          itemtypeID: itID,
          titre: input.title,
          mail: input.email,
          tel: input.tel
        }),
        requesttypes_id: 7,
        type: 1,
        locations_id: itID,
        itilcategories_id: cat,
        content: input.content
        //entities_id: ent,
        //is_visible: 1,
      };

      this.overlay = true;

      TICKET_API.createTicket(payload)
        .then(response => {
          let ticketId = response.data.id;
          const payload2 = {
            itemtype: it,
            items_id: itID,
            tickets_id: ticketId
          };
          TICKET_API.linkTicket(payload2, ticketId).then(() => {
            this.addEventDialog = false;
            this.overlay = false;
            if (this.isDirectLink) {
              Swal.fire({
                title: "Merci de votre demande!",
                text: "Vous allez recevoir un mail de suivi",
                icon: "success",
                customClass: mySwalClass,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2500
              });
            } else {
              Swal.fire({
                title: "Merci de votre demande!",
                text: "Vous allez recevoir un mail de confirmation",
                icon: "success",
                customClass: mySwalClass,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2500
              });
            }
          });
        })
        .finally(() => {
          if (this.isDirectLink) {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Error de creation"
          });
        });
    },
    closeEventDialog() {
      this.addEventDialog = false;
    },
    dataRange() {
      if (this.slider === "") {
        this.slider = this.sliderInit;
      }
      this.slider = !this.slider;
      if (this.slider === true) {
        localStorage.removeItem("custom_range");
      } else {
        localStorage.setItem("custom_range", "0-1000");
      }
    },
  },
  computed: {
    isDirectLink() {
      return this.$route.meta.directLink;
    },
    sliderInit() {
      if (localStorage.getItem("custom_range")) return false;
      else return true;
    },
  }
};
</script>
